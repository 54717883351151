import { SubmitHandler, useForm, Validate } from 'react-hook-form'
import { CleanValueIcon } from '../components/CleanValueIcon'
import Header from '../components/Header'
import ethIcon from '../images/createNFT/ethIcon.png'
import polygonIcon from '../images/createNFT/polygonIcon.png'
import solanaIcon from '../images/createNFT/solanaIcon.png'
import klaythIcon from '../images/createNFT/klaythIcon.png'
import uploadIcon from '../images/createNFT/uploadIcon.png'
import { useMoralis } from '../hooks/moralis'
import uploadErrorIcon from '../images/createNFT/uploadErrorIcon.png'
import { ChangeEvent, useState, FC, useEffect } from 'react'
import { ErrorMessage } from '../components/ErrorMessage'
import Toast from '../components/toast'
import { toast } from 'react-toastify'
import Modal from '../components/Popup'
import UploadPreview from '../components/UploadPreview'
type CreateFormType = {
  worksName: string
  media: FileList
  previewImage: FileList
  description: string
  link: string
  blockchain: string
}

const CreateNFT: FC = () => {
  // whether to show the previewImage field
  const { uploadMetadata, Moralis, uploadFilesIPFS } = useMoralis()
  const [previewImage, setPreviewImage] = useState(false)
  const [previewModalVisible, setPreviewModalVisible] = useState(false)
  const [descriptionLength, setDescriptionLength] = useState(0)
  const blockchainSelectList = [
    { icon: ethIcon, name: 'Ethereum Mainnet', value: 'eth' },
    { icon: ethIcon, name: 'Ropsten', value: 'ropsten' },
    { icon: ethIcon, name: 'Rinkeby ', value: 'Rinkeby' },
    { icon: polygonIcon, name: 'polygon', value: 'Rinkeby' },
    { icon: solanaIcon, name: 'Solana(In development)', value: '' },
    { icon: klaythIcon, name: 'Klayth(In development)', value: '' },
  ]
  const [blockchainListVisible, setBlockchainListVisible] = useState(false)
  const [currentBlockchain, setCurrentBlockchain] = useState({
    icon: ethIcon,
    name: 'Ethereum Mainnet',
    value: 'eth',
  })
  const [mediaType, setMediaType] = useState('mp3')
  // Media Field URL
  const [mediaUploadUrl, setMediaUploadUrl] = useState<string | null>(null)
  // PreviewImage Field URL
  const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null)
  // Preview Modal Image URL
  const [previewModalUrl, setPreviewModalUrl] = useState<string>('')
  const handleSelectBlockchain = (selected: {
    icon: any
    value: string
    name: string
  }) => {
    setCurrentBlockchain(selected)
    setBlockchainListVisible(false)
  }

  const {
    register,
    handleSubmit,
    reset,
    resetField,
    watch,
    getValues,
    formState: { errors },
  } = useForm<CreateFormType>()

  const mediaUploadChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = getValues('media')?.[0]
    toast.promise(
      uploadFilesIPFS?.(file).then((res: any) => {
        setMediaUploadUrl(res)
      }),
      {
        pending: 'uploading file',
        success: 'Congratulations, the file has been uploaded 👌',
        error: 'Sorry, there seems to be some problem 🤯',
      },
    )

    file && setMediaUploadUrl(window.URL.createObjectURL(file) ?? null)
    const type = e?.target?.value.slice(-3)
    setMediaType(type)
    if (['mp3', 'mp4'].includes(type)) {
      return setPreviewImage(true)
    }
    return setPreviewImage(false)
  }
  const previewImageUploadChange = () => {
    const file = getValues('previewImage')?.[0]
    file && setPreviewImageUrl(window.URL.createObjectURL(file) ?? null)
  }

  const mediaUploadValidate:
    | Validate<FileList>
    | Record<string, Validate<FileList>>
    | undefined = file => {
    // TODO: Validate
    return !!file?.length
  }

  const previewImageValidate:
    | Validate<FileList>
    | Record<string, Validate<FileList>>
    | undefined = file => {
    // TODO: Validate
    return !!file?.length
  }

  const onSubmit: SubmitHandler<CreateFormType> = data => {
    Reflect.deleteProperty(data, 'media')
    Reflect.deleteProperty(data, 'previewImage')
    uploadMetadata?.({
      ...data,
      blockchain: currentBlockchain.value,
      fileURI: mediaUploadUrl,
    }).then((res: any) => {
      console.log(res)
      setMediaUploadUrl(null)
      setPreviewImageUrl(null)
      setPreviewImage(false)
      reset()
    })
    // toast.success('Successfully Created NFT')
  }

  return (
    <div
      className="bg-[#F3F7FA]"
      onClick={() => setBlockchainListVisible(false)}
    >
      <Header />
      <Toast />
      <div className="w-full pt-20 bg-[url('../images/createNFT/bg.png')] bg-no-repeat bg-top bg-cover text-sm overflow-hidden sm:pt-[57px] md:px-16 sm:px-4">
        <div className="text-[34px] font-boldText font-bold mt-[50px] text-center leading-[52px] sm:mt-[42px] sm:text-[32px] sm:leading-[36px]">
          Create Your NFT
        </div>
        <div className="mt-2 text-center text-[18px] sm:mt-[23px] sm:text-[14px] sm:text-[#333] sm:leading-[22px] sm:px-[28px]">
          Now, there is an opportunity for you to become a crypto artist
        </div>
        <form
          className="mb-[102px] mt-[47px] mx-auto max-w-[576px] bg-[rgba(254,254,254,0.3)] rounded-2xl px-[60px] pt-[42px] pb-[77px] sm:mt-4 sm:px-0 sm:pt-[42px] sm:pb-0"
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* Works Name */}
          <div>
            <div className="text-[24px] leading-[26px] after:content-['*'] after:w-1 after:h-4 after:text-red-500">
              Works name
            </div>
            <div className="mt-[10px] text-[#828282]">
              Fill in the name of the work, which is also used as the name of
              the NFT
            </div>
            <div className="mt-8">
              <div className="relative">
                <input
                  {...register('worksName', {
                    required: 'Please enter your worksName',
                  })}
                  className={`h-[50px] w-full bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] outline-none p-4 text-[#828282] text-sm placeholder:text-[#828282] rounded border border-solid caret-[#1E94FC] focus:shadow-[0_6px_18px_4px_#C0E2FF] hover:shadow-[0_6px_18px_4px_#C0E2FF] transition-all duration-300 ${
                    errors.worksName
                      ? 'border-red-500 focus:shadow-none hover:shadow-none'
                      : 'border-transparent focus:border-[#1E94FC] hover:border-[#1E94FC]'
                  }`}
                  placeholder="Contents"
                />
                {watch('worksName') && (
                  <CleanValueIcon onClick={() => resetField('worksName')} />
                )}
              </div>
              {errors.worksName && (
                <ErrorMessage message={errors.worksName.message} />
              )}
            </div>
          </div>

          {/* Image, Video, Audio, or 3D Model */}
          <div className="mt-[72px]">
            <div className="text-[24px] leading-[26px] after:content-['*'] after:w-1 after:h-4 after:text-red-500">
              Image, Video, Audio, or 3D Model
            </div>
            <div className="mt-[10px] text-[#828282]">
              File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV,
              OGG, GLB,GLTF. Max size: 100 MB
            </div>
            <input
              {...register('media', {
                required: 'Please upload media file',
                validate: mediaUploadValidate,
                onChange: mediaUploadChange,
              })}
              id="media"
              type="file"
              className="hidden"
            />
            {mediaUploadUrl ? (
              {
                jpg: (
                  <UploadPreview
                    previewImageUrl={mediaUploadUrl}
                    previewBtnCallback={e => {
                      e.stopPropagation()
                      setPreviewModalVisible(true)
                      setPreviewModalUrl(mediaUploadUrl)
                    }}
                    deleteBtnCallback={e => {
                      e.stopPropagation()
                      setMediaUploadUrl(null)
                      setPreviewImageUrl(null)
                      setPreviewImage(false)
                      resetField('media')
                    }}
                  />
                ),
                mp3: (
                  <div className="relative mt-8">
                    <audio controls src={mediaUploadUrl} className="w-[85%]">
                      Your browser does not support the
                      <code>audio</code> element.
                    </audio>
                    <CleanValueIcon
                      className="w-6 h-6"
                      onClick={() => {
                        setMediaUploadUrl(null)
                        setPreviewImageUrl(null)
                        setPreviewImage(false)
                        resetField('media')
                      }}
                    />
                  </div>
                ),
                mp4: (
                  <div className="relative mt-8 w-max">
                    <video controls className="w-[220px]">
                      <source src={mediaUploadUrl} type="video/mp4" />
                      Sorry, your browser doesn't support embedded videos.
                    </video>
                    <CleanValueIcon
                      className="top-0 right-0 w-8 h-8 translate-x-1/2"
                      onClick={() => {
                        setMediaUploadUrl(null)
                        setPreviewImageUrl(null)
                        setPreviewImage(false)
                        resetField('media')
                      }}
                    />
                  </div>
                ),
              }[mediaType]
            ) : (
              <div>
                <label
                  htmlFor="media"
                  className={`w-[220px] h-[158px] mt-8 bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] rounded cursor-pointer flex border border-solid hover:shadow-[0_6px_18px_4px_#C0E2FF] transition-all duration-300 ${
                    errors.media
                      ? 'border-red-500 hover:shadow-none'
                      : 'border-transparent hover:border-[#1E94FC]'
                  }`}
                >
                  <img
                    src={errors.media ? uploadErrorIcon : uploadIcon}
                    alt="uploadIcon"
                    className="w-12 h-12 m-auto"
                  />
                </label>
                {errors.media && (
                  <ErrorMessage message={errors.media.message} />
                )}
              </div>
            )}
          </div>

          {/* Preview Image* */}
          {previewImage && (
            <div className="mt-[72px]">
              <div className="text-[24px] leading-[26px] after:content-['*'] after:w-1 after:h-4 after:text-red-500">
                Preview Image
              </div>
              <div className="mt-[10px] text-[#828282]">
                Because you’ve included multimedia, you’ll need to provide an
                image (PNG, JPG, or GIF) for the card display of your item.
              </div>
              <input
                {...register('previewImage', {
                  required: 'Please upload preview image',
                  validate: previewImageValidate,
                  onChange: previewImageUploadChange,
                })}
                id="previewImage"
                accept=".jpg"
                type="file"
                className="hidden"
              />
              {previewImageUrl ? (
                <UploadPreview
                  previewImageUrl={previewImageUrl}
                  previewBtnCallback={e => {
                    e.stopPropagation()
                    setPreviewModalVisible(true)
                    setPreviewModalUrl(previewImageUrl)
                  }}
                  deleteBtnCallback={e => {
                    e.stopPropagation()
                    setPreviewImageUrl(null)
                    resetField('previewImage')
                  }}
                />
              ) : (
                <>
                  <label
                    htmlFor="previewImage"
                    className={`w-[220px] h-[158px] mt-8 bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] rounded cursor-pointer flex border border-solid hover:shadow-[0_6px_18px_4px_#C0E2FF] transition-all duration-300 ${
                      errors.previewImage
                        ? 'border-red-500 hover:shadow-none'
                        : 'border-transparent hover:border-[#1E94FC]'
                    }`}
                  >
                    <img
                      src={errors.previewImage ? uploadErrorIcon : uploadIcon}
                      alt="uploadIcon"
                      className="w-12 h-12 m-auto"
                    />
                  </label>
                  {errors.previewImage && (
                    <ErrorMessage message={errors.previewImage.message} />
                  )}
                </>
              )}
            </div>
          )}

          {/* Description */}
          <div className="mt-[72px]">
            <div className="text-[24px] leading-[26px] after:content-['*'] after:w-1 after:h-4 after:text-red-500">
              Description
            </div>
            <div className="mt-[10px] text-[#828282]">
              Briefly describe your work
            </div>
            <div className="mt-8">
              <div className="relative">
                <textarea
                  {...register('description', {
                    required: 'Please enter your description',
                    onChange: e => setDescriptionLength(e.target.value.length),
                  })}
                  className={`w-full min-h-[172px] resize-none rounded outline-none p-4 text-[#828282] placeholder:text-[#828282] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] border border-solid caret-[#1E94FC] focus:shadow-[0_6px_18px_4px_#C0E2FF] pb-8 hover:shadow-[0_6px_18px_4px_#C0E2FF] transition-all duration-300 ${
                    errors.description
                      ? 'border-red-500 focus:shadow-none hover:shadow-none'
                      : 'border-transparent focus:border-[#1E94FC] hover:border-[#1E94FC]'
                  }`}
                  maxLength={200}
                  placeholder="Provide a detailed description of your works."
                />
                {watch('description') && (
                  <CleanValueIcon
                    className="!right-[75px] top-[87%]"
                    onClick={() => resetField('description')}
                  />
                )}
                <div className="text-[#BDBDBD] text-[14px] leading-[14px] absolute bottom-4 right-[18px]">
                  <span className="text-[#1E94FC]">{descriptionLength}</span>
                  /200
                </div>
              </div>
              {errors.description && (
                <ErrorMessage message={errors.description.message} />
              )}
            </div>
          </div>

          {/* Link */}
          <div className="mt-[72px]">
            <div className="text-[24px] leading-[26px]">Link</div>
            <div className="mt-[10px] text-[#828282]">
              Feel free to include an additional demo links to your works (if
              any)
            </div>
            <div className="mt-8">
              <div className="relative">
                <input
                  {...register('link')}
                  className={`h-[50px] w-full bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] outline-none p-4 text-[#828282] text-sm placeholder:text-[#828282] rounded border border-solid caret-[#1E94FC] focus:shadow-[0_6px_18px_4px_#C0E2FF] hover:shadow-[0_6px_18px_4px_#C0E2FF] transition-all duration-300 ${
                    errors.link
                      ? 'border-red-500 focus:shadow-none hover:shadow-none'
                      : 'border-transparent focus:border-[#1E94FC] hover:border-[#1E94FC]'
                  }`}
                  placeholder="https://yoursite.io/"
                />
                {watch('link') && (
                  <CleanValueIcon onClick={() => resetField('link')} />
                )}
              </div>
            </div>
          </div>

          {/* Blockchain */}
          <div className="mt-[72px]">
            <div className="text-[24px] leading-[26px]">Blockchain</div>
            <div className="relative mt-8 select-none">
              <div
                className="h-16 bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] rounded p-4 flex items-center justify-between cursor-pointer hover:shadow-[0_6px_18px_4px_#C0E2FF] transition-all duration-300 border border-solid border-transparent hover:border-[#1E94FC]"
                onClick={e => {
                  e.stopPropagation()
                  setBlockchainListVisible(!blockchainListVisible)
                }}
              >
                <div className="flex items-center">
                  <img
                    src={currentBlockchain.icon}
                    alt="blockchain"
                    className="w-8 h-8 rounded-full"
                  />
                  <span className="text-[16px] leading-[22px] ml-[10px]">
                    {currentBlockchain.name}
                  </span>
                </div>
                <svg
                  className="w-4 h-4 text-[#B0B0B0]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              <div
                className={`w-full bg-white absolute shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] rounded mt-2 overflow-hidden transition-all ${
                  blockchainListVisible ? 'h-[188px]' : 'h-0'
                }`}
              >
                {blockchainSelectList.map((x, i) => (
                  <div
                    key={x.name}
                    className="flex items-center px-4 last:mb-[6px] hover:bg-[#F0F8FF] py-[6px] cursor-pointer first:mt-[6px]"
                    onClick={e => {
                      e.stopPropagation()
                      handleSelectBlockchain(x)
                    }}
                  >
                    <img
                      src={x.icon}
                      alt="blockchain"
                      className="w-8 h-8 rounded-full"
                    />
                    <span className="text-[16px] leading-[22px] ml-[10px]">
                      {x.name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="bg-[#1E94FC] rounded w-full h-[62px] text-white shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] text-[18px] leading-[30px] mt-[72px]"
          >
            Create
          </button>
        </form>
      </div>
      <Modal
        visible={previewModalVisible}
        onOk={() => setPreviewModalVisible(false)}
        onCancel={() => setPreviewModalVisible(false)}
      >
        <img
          src={previewModalUrl}
          alt="previewModalUrl"
          className="object-cover w-full h-full rounded-2xl sm:my-12 sm:h-[280px]"
        />
      </Modal>
    </div>
  )
}

export default CreateNFT
